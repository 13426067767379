import React from "react";

const Loader = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-8 -mt-10">
      <svg
        className="animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        width="79"
        height="79"
        viewBox="0 0 79 79"
        fill="none"
      >
        <path
          d="M0.25 39.5C0.25 61.1772 17.8228 78.75 39.5 78.75C61.1772 78.75 78.75 61.1772 78.75 39.5C78.75 17.8228 61.1772 0.25 39.5 0.25C17.8228 0.25 0.25 17.8228 0.25 39.5ZM69.6818 39.5C69.6818 56.169 56.169 69.6818 39.5 69.6818C22.831 69.6818 9.31816 56.169 9.31816 39.5C9.31816 22.831 22.831 9.31816 39.5 9.31816C56.169 9.31816 69.6818 22.831 69.6818 39.5Z"
          fill="#F5F5F5"
        />
        <path
          d="M74.2159 39.5C76.72 39.5 78.7771 37.4633 78.4884 34.9759C77.7735 28.8144 75.6052 22.8871 72.1352 17.6939C67.8223 11.2393 61.6923 6.20848 54.5203 3.23774C47.3483 0.266999 39.4565 -0.510285 31.8427 1.00419C24.229 2.51865 17.2353 6.25685 11.7461 11.7461C6.25686 17.2353 2.51866 24.229 1.00419 31.8427C-0.510285 39.4565 0.266994 47.3483 3.23773 54.5203C6.20847 61.6923 11.2392 67.8223 17.6939 72.1352C22.8871 75.6052 28.8144 77.7735 34.9759 78.4884C37.4633 78.7771 39.5 76.72 39.5 74.2159C39.5 71.7118 37.4588 69.7167 34.9829 69.3419C30.6184 68.6813 26.4327 67.0681 22.7319 64.5953C17.7685 61.2789 13.9 56.5651 11.6156 51.0501C9.33123 45.5351 8.73353 39.4665 9.89811 33.6118C11.0627 27.7571 13.9372 22.3792 18.1582 18.1582C22.3792 13.9372 27.7571 11.0627 33.6118 9.89811C39.4665 8.73353 45.5351 9.33124 51.0501 11.6156C56.5651 13.9 61.2789 17.7685 64.5953 22.7319C67.0681 26.4327 68.6813 30.6184 69.3419 34.9829C69.7167 37.4588 71.7118 39.5 74.2159 39.5Z"
          fill="#5B93FF"
        />
      </svg>

      <p className="text-[#858585] text-base font-medium font-opensans">
        Improving your text...
      </p>
    </div>
  );
};

export default Loader;
