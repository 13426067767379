import React from "react";
import CleanButton from "assets/cleanBtn.svg";

const ClearText = ({ text, handleClearText,loading }) => {
  return (
    <div className={` border-b border-[#B0B0B0] flex items-center justify-between`} >
      {/* <p className=" text-[#2B2B2B] text-base font-opensans font-bold">Text</p> */}
 
      {text && (
        <img
          src={CleanButton}
          alt="clean-button"
          onClick={handleClearText}
          className="cursor-pointer  absolute right-[14px]"
        />
      )}
    </div>
  );
};

export default ClearText;
