import React from "react";

const Icons = ({ status, setStatus }) => {
  if (status === "edited")
    return (
      <svg
        className="cursor-pointer"
        onClick={() => setStatus("original")}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
      >
        <path
          d="M11.501 3.5H6.50098V0.5L0.500977 4.5L6.50098 8.5V5.5H11.501C13.701 5.5 15.501 7.3 15.501 9.5C15.501 11.7 13.701 13.5 11.501 13.5H6.50098V15.5H11.501C14.801 15.5 17.501 12.8 17.501 9.5C17.501 6.2 14.801 3.5 11.501 3.5Z"
          fill="#69AAE8"
        />
      </svg>
    );

  return (
    <svg
      className="cursor-pointer"
      onClick={() => setStatus("edited")}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
    >
      <path
        d="M6.50098 3.5H11.501V0.5L17.501 4.5L11.501 8.5V5.5H6.50098C4.30098 5.5 2.50098 7.3 2.50098 9.5C2.50098 11.7 4.30098 13.5 6.50098 13.5H11.501V15.5H6.50098C3.20098 15.5 0.500977 12.8 0.500977 9.5C0.500977 6.2 3.20098 3.5 6.50098 3.5Z"
        fill="#69AAE8"
      />
    </svg>
  );
};

export default Icons;
