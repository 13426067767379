import axios from 'axios';
import { Languages } from 'components/QualityTools/LanguagesData';


const detectLanguage = async (text) => {
  try {
    const response = await axios.post(
      "https://api.machinetranslation.com/v1/detect/language",
      {
        text: text.slice(0, 50), 
      }
    );

    const languageCode = response.data?.data?.language_probability?.code;
    const language = Languages.find((lang) => lang?.code === languageCode);

    return {
      languageCode,
      languageName: language ? language.name : null,
    };
  } catch (error) {
    console.error("Error detecting language:", error);
    return { languageCode: null, languageName: null };
  }
};

export default detectLanguage;