import React, { useState } from "react";
import CleanButton from "assets/cleanBtn.svg";

const Guidelines = ({
  guidelines,
  setGuidelines,
  inputText,
  handleClick,
  page,
  loading,
  isButtonDisabled 
}) => {
  const [words, setWords] = useState(0);
 
  return (
    <div className="bg-white  h-[353px] flex-[0.8] p-4 rounded-lg shadow-box">
      <div className="bg-[#F5F5F5] w-full h-[282px] overflow-y-auto p-2 rounded relative">
        {page == "result" ? (
          <p className="w-full min-h-[227px] text-[#6E6E6E]">{guidelines}</p>
        ) : (
          <textarea
            onChange={(e) => {
              setGuidelines(e.target.value);
              const wordLength = e.target.value
                .trim()
                .split(/\s+/)
                .filter((word) => word.length > 0).length;
              setWords(wordLength);
            }}
            autoFocus
            value={guidelines}
            className="pr-6 bg-transparent border-none outline-none w-full min-h-[227px] resize-none text-sm font-primary text-[#6E6E6E]"
            placeholder="Enter Your Own Guidelines (optional)
"
          />
        )}
        <p className="text-end font-primary text-[12px] text-[#9C9C9C]">
          {words}/300
        </p>
        {guidelines && page !== "result" && (
          <img
            src={CleanButton}
            alt="clean-button"
            onClick={() => {
              setGuidelines("");
              setWords(0);
            }}
            className="cursor-pointer absolute right-2 top-3"
          />
        )}
      </div>

      <button
        
        disabled={isButtonDisabled}
        onClick={handleClick}
        className={`rounded  ${
          isButtonDisabled ? "bg-[#C7C7C7] cursor-not-allowed" : "bg-[#5B93FF]"
        }  w-full px-6 py-2 mt-3 text-white text-sm font-medium font-primary`}
      >
        Improve Now
      </button>
    </div>
  );
};

export default Guidelines;
