import React, { useState } from "react";
import CleanButton from "assets/cleanBtn.svg";
import { navigate } from "gatsby";
import Icons from "./Icons";

const OutputHeader = ({status,setStatus,setResult, setGuideLines,setInputText ,setUrlText}) => {

  return (
    <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
      <div className="flex gap-2 items-center">
        <p className=" text-[#2B2B2B] text-base font-opensans font-bold">
          Text
        </p>
        {/* {guidelines && ( */}
          <button className="capitalize px-2 py-1 rounded bg-[#F0F5FF] text-[#5B93FF] text-[12px] font-opensans font-semibold">
            {status}
          </button>
        {/* )} */}
      </div>

      <div className="flex gap-2.5 items-center">
        {/* {guidelines && */}
         <Icons status={status} setStatus={setStatus} />
         {/* } */}
        <img
          src={CleanButton}
          alt="clean-button"
          onClick={() => { 
            setResult(""); 
            setGuideLines(""); 
            setInputText("");
            setUrlText("");
            navigate("/tools/ai-writing-assistant"); 
          }}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

export default OutputHeader;
