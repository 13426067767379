import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import ToolsBanner from "components/commontools/toolsBanner";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useState, useRef, useEffect } from "react";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import ToolFaq from "components/QualityTools/ToolFaq";
import { aiWritingAssistant } from "components/FAQ/config";
import { navigate } from "gatsby";
import useDebounce from "components/QualityTools/useDebounce";
import detectLanguage from "utils/detectLanguage";
import Guidelines from "components/AIWritingAssistant/Guidelines";
import ClearText from "components/ClearText";
import Loader from "components/AIWritingAssistant/loader";
import { useLocation } from "@reach/router";
import EditingOutput from "components/AIWritingAssistant/EditingOutput";
import EditingInput from "components/AIWritingAssistant/EditingInput";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [guidelines, setGuideLines] = useState("");
  const [inputText, setInputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [isEnterText, setIsEnterText] = useState(true);
  const [urlText, setUrlText] = useState("");
  const refDiv = useRef(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const debouncedInputText = useDebounce(inputText, 3000);
  const [result, setResult] = useState("");
  const [status, setStatus] = useState("edited");
  const [shareId, setShareId] = useState("");
  const [tooltipContent, setTooltipContent] = useState("Copy");
  const location = useLocation();
  const url = location.href;

  const toggleInput = (isTextInput) => {
    setIsEnterText(isTextInput);
    setUrlText("");
    setInputText("");
  };

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);
  const detectLanguageForSource = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
    }
  };

  const handleClick = async () => {
    setLoading(true);
    let payload;
    if (urlText){
      payload = {
        tool_name: "ai_editing_assistant",
        user_url: urlText,
      };
    } else {
      payload = {
        tool_name: "ai_editing_assistant",
      user_text: inputText,
      source_language_code: inputLang ?? "en",
      };
    }

    if (guidelines) {
      payload.guideline = guidelines;
    }
    try {
      const response = await http().post(endpoints.forms.aiTools, payload);
      const newShareId = response?.meta?.share_id;
      setShareId(newShareId)
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }

      setResult(response);
       setLoading(false);
      // navigate(`/tools/ai-writing-assistant/result?share-id=${newShareId}`);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const handleLinkChange = (e) => {
    const text = e.target.value;
    setUrlText(text);
    // setHasSummary(false);
  };
  const clearLink = () => {
    setUrlText("");
  };
  const handleClearText = () => {
    setInputLang("");
    setInputText("");
    setWordCountInput(0);
    setResult("")
  };
  const getWords = () => {
    const text =
      status === "original"
        ? result?.meta?.user_text
        : result?.result?.revised_text;
    return text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
  };
  const shareresult = () => {
    navigator.clipboard.writeText(`${url}/result?share-id=${shareId}`);
    setTooltipContent("Link copied");

    setTimeout(() => {
      setTooltipContent("Copy");
    }, 2000);
  };
  const isButtonDisabled = !urlText && !(inputText && inputLang);
  return (
    <Layout>
      <SEO
        title="Free AI Text Editor"
        description="Edit text effortlessly with the AI-Powered Text Editing Tool. Input custom instructions to refine text in multiple languages with precision and speed. Fast, flexible, and user-friendly."
        slug="/tools/ai-writing-assistant​​​"
      />
      <div className="bg-[#F5F5F5]">
        <div className="pt-5">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="AI Text Editing Tool"
            title="Edit Multilingual Texts Quickly and Easily Using AI"
            description="This AI Text Editor makes text editing simpler than ever. Write custom instructions to tailor your multilingual text to your needs. It’s the perfect tool for refining your writing with accuracy and speed."
          />
          <div className=" flex lg:flex-row  flex-col gap-4 mt-12">
            {!result ? (
            <div className="flex flex-col gap-3 w-full lg:flex-[1.2]">
              <div className="lg:flex items-center  rounded-lg shadow-lg bg-white ">
                {loading ? (
                  <div className="h-[359px] w-full relative p-4">
                    <div className="flex justify-between items-center border-b border-[#B0B0B0] pb-3 ">
                     <div className=" text-[#6E6E6E] font-opensans font-bold">
                        <button
                          onClick={() => toggleInput(true)}
                          className={`py-1 px-3 rounded-md font-semibold ${
                            isEnterText
                              ? "text-white bg-[#7AA7FF]"
                              : "text-[#7AA7FF] bg-[#F0F5FF]"
                          }`}
                        >
                          Enter Text
                        </button>
                        <button
                          onClick={() => toggleInput(false)}
                          className={`py-1 px-3 rounded-md font-semibold ${
                            isEnterText
                              ? "text-[#7AA7FF] bg-[#F0F5FF]"
                              : "text-white bg-[#7AA7FF]"
                          }`}
                        >
                          Enter URL
                        </button>
                      </div>
                    <ClearText
                      text="loading"
                      handleClearText={handleClearText}
                      loading = {loading}
                    />
                    </div>
                    <div className="h-full grid place-content-center">
                      <Loader />
                    </div>
                  </div>
                ) : (
                  <ToolInput
                    lang={inputLang}
                    text={inputText}
                    wordCount={wordCountInput}
                    setText={setInputText}
                    setInputLang={setInputLang}
                    setWordCount={setWordCountInput}
                    iscleanBtn={true}
                    // isDifficulty={true}
                    urlText={urlText}
                    setUrlText={setUrlText}
                    refDiv={refDiv}
                    handleClearText={handleClearText}
                    placeholder="Type your text here or"
                    tooltype="pre"
                    clearLink={clearLink}
                    handleLinkChange={handleLinkChange}
                    isEnterText={isEnterText}
                  >
                    <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                      <div className=" text-[#6E6E6E] font-opensans font-bold">
                        <button
                          onClick={() => toggleInput(true)}
                          className={`py-1 px-3 rounded-md font-semibold ${
                            isEnterText
                              ? "text-white bg-[#7AA7FF]"
                              : "text-[#7AA7FF] bg-[#F0F5FF]"
                          }`}
                        >
                          Enter Text
                        </button>
                        <button
                          onClick={() => toggleInput(false)}
                          className={`py-1 px-3 rounded-md font-semibold ${
                            isEnterText
                              ? "text-[#7AA7FF] bg-[#F0F5FF]"
                              : "text-white bg-[#7AA7FF]"
                          }`}
                        >
                          Enter URL
                        </button>
                      </div>
                      <ClearText
                        text={inputText}
                        handleClearText={handleClearText}
                        loading = {loading}
                        isEnterText={isEnterText}
                      />
                      <div className="flex gap-3"></div>
                    </div>
          
                  </ToolInput>
                )}
              </div>
            </div>
            ) : (
              <EditingInput status={status} setStatus={setStatus} result={result} getWords ={getWords} tooltipContent={tooltipContent} shareresult = {shareresult} setResult = {setResult} setGuideLines={setGuideLines} setInputText={setInputText} setUrlText = {setUrlText} />
            )}
            <Guidelines
              guidelines={guidelines}
              setGuidelines={setGuideLines}
              inputText={inputText}
              handleClick={handleClick}
              loading={loading}
              isButtonDisabled = { isButtonDisabled }
            />
          </div>
          {result && (
          <EditingOutput
            analysisResult={result?.result?.detailed_summary_of_revisions}
            guidelines={result?.meta?.guideline ?? ""}
          />
        )}

          <p className="mt-8 md:mt-12 text-lg text-center font-opensans text-[#424242]">
            This AI Text Editor makes text editing simpler than ever. Write
            custom instructions to tailor your multilingual text to your needs.
            It’s the perfect tool for refining your writing with accuracy and
            speed.
          </p>
        </div>
      </div>
      <div>
        <ToolFaq QaToolFaqData={aiWritingAssistant} />
      </div>
    </Layout>
  );
};

export default Index;
