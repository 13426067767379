import React from "react";
import OutputHeader from "./OutputHeader";
import CopyText from "./CopyText";
import CopyLink from "assets/copu_link_icon.svg";
import { Tooltip } from "react-tooltip";

const EditingInput = ({
  status,
  setStatus,
  result,
  getWords,
  tooltipContent,
  shareresult,
  setResult,
 setGuideLines,
 setInputText,
 setUrlText
}) => {
  return (
    <div className="p-4 rounded-lg shadow-box bg-white relative flex-[1.2] ">
      <OutputHeader status={status} setStatus={setStatus} setResult = {setResult} setGuideLines = {setGuideLines} setInputText={setInputText} setUrlText ={setUrlText}  />
      <p
        id="writing_text"
        className="pr-5 text-base font-opensans text-[#424242] mt-4 anonymize_tool max-h-[200px] overflow-y-scroll"
        dangerouslySetInnerHTML={{
          __html: (status === "original"
            ? result?.meta?.user_text
            : result?.result?.revised_text
          )
            .replace(/\n\n/g, "<br><br>")
            .replace(/\n/g, "<br>"),
        }}
      />

      <div className="mt-10 flex items-center justify-between w-full">
        <span className="text-[#9C9C9C] text-sm font-opensans font-semibold">
          {getWords()} words
        </span>
        <div className="flex items-center gap-3">
          <CopyText id="writing_text" />
          {shareresult && (
            <button
              className="flex items-center gap-2 w-max"
              data-tooltip-id="linkcopy-tooltip"
              data-tooltip-content={tooltipContent}
              onClick={shareresult}
            >
              <img src={CopyLink} alt="copy_link" />
            </button>
          )}
        </div>
        <Tooltip
          id="linkcopy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
      </div>
    </div>
  );
};

export default EditingInput;
