import React, { useState } from "react";
import { Tooltip } from "react-tooltip";

const CopyText = ({ id }) => {
  const [copy, setCopy] = useState("Copy");

  const handleCopy = () => {
    const text = document.getElementById(id).innerText;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopy("Copied");
        setTimeout(() => {
          setCopy("Copy");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy data: ", error);
      });
  };
  return (
    <>
      <svg
        onClick={handleCopy}
        data-tooltip-id="copy-tooltip"
        data-tooltip-content={copy}
        className="cursor-pointer outline-none"
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.28628 0C5.86193 0 5.45497 0.168571 5.15491 0.468629C4.85485 0.768687 4.68628 1.17565 4.68628 1.6V3.2H6.28628V1.6H15.0863V10.4H13.4863V12H15.0863C15.5106 12 15.9176 11.8314 16.2177 11.5314C16.5177 11.2313 16.6863 10.8243 16.6863 10.4V1.6C16.6863 1.17565 16.5177 0.768687 16.2177 0.468629C15.9176 0.168571 15.5106 0 15.0863 0H6.28628ZM2.28628 4C1.86193 4 1.45497 4.16857 1.15491 4.46863C0.85485 4.76869 0.686279 5.17565 0.686279 5.6V14.4C0.686279 14.8243 0.85485 15.2313 1.15491 15.5314C1.45497 15.8314 1.86193 16 2.28628 16H11.0863C11.5106 16 11.9176 15.8314 12.2177 15.5314C12.5177 15.2313 12.6863 14.8243 12.6863 14.4V5.6C12.6863 5.17565 12.5177 4.76869 12.2177 4.46863C11.9176 4.16857 11.5106 4 11.0863 4H2.28628ZM2.28628 5.6H11.0863V14.4H2.28628V5.6Z"
          fill="#5B93FF"
        />
      </svg>
      <Tooltip
        id="copy-tooltip"
        place="top"
        multiline={true}
        className="z-50"
      />
    </>
  );
};

export default CopyText;
